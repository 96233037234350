@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;600;700&family=Montserrat:wght@300;400;700;800&display=swap');

@font-face {
  font-family: 'InkFree';
  src: local('InkFree'), url('./fonts/Inkfree.woff2') format('woff2');
}

body {
  margin: 0;
  font-family: 'Josefin Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}